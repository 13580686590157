.margin-bottom	{
	margin-bottom: rem($base-line-height);
}

.highlight {
	color: $primary;
}

.btn {
	@extend %animated-transform;
	appearance: none;
	background: transparent;
	border: none;
	color: $primary;
	cursor: pointer;
	display: inline-flex;
	align-items:center;
	font-size: rem(16px);
	line-height: rem(24px);
	font-weight: bold;
	padding-left:rem(18px);
	text-align: center;
	text-decoration: none;
	font-family: $main-font;
	position: relative;
	padding-right:rem($base-gap);

	@include breakpoint(large) {
		font-size: rem(18px);
		line-height: rem(28px);
	}

	@media screen and (min-width:1800px) {
		padding-left:rem(30px);
		padding-right:rem(30px);
	}

	&:before {
		content:"";
		display:block;
		width:rem(61px);
		height:rem(61px);
		background:rgba($primary,.08);
		border-radius:100%;
		position: absolute;
		left:0;
		top:50%;
		transform:translateY(-50%);
		@extend %animated-transform;
	}

	&:after {
		content:map-get($icon-map, angle-right);
		font-family: $icon-font;
		vertical-align: middle;
		margin-left:rem(6px);
	}

	&:hover {
		color: $dark;

		&:before {
			background:rgba($primary,.28);
		}
	}

	*[class^="icon"] {
		margin-left: 0.5rem;
		vertical-align: middle;
	}
}

.clear {
	clear: both;
	float: none;
}

.clearfix {
	@include pie-clearfix;
}

.hidden {
	display: none !important;
}

.hideText {
	@include hide-text();
	display: inline-block;
	overflow: hidden;
	width: 0;
}

.float-left {
	float: left;
}

img.float-left {
	margin-right: 1rem;
}

.float-right {
	float: right;
}

img.float-right {
	margin-left: 1rem;
}

.fluid {
	height: auto;
	width: 100%;
}

.non-fluid,
.non-fluid img {
	width: auto !important;
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.wide {
	width: 100%;
	display: block;
}

/* 16:9 video resolutions */
.video-container {
	&:not(.video-tag) {
		position: relative;
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%;
		position: relative;

		iframe {
			border: none;
			height: 100%;
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
		}
	}

	video {
		width: 100%;
		height: auto;
	}
}
.google-maps {
	@extend .video-container;

	@include breakpoint(medium) {
		height: rem(400px);
	}
}

body {
	&:before {
		$content: "default:" + $bpContext;
		width: str_length($content) * 6pt + $base-gap * 2;

		@each $point, $width in $gridMap {
			$content: $content + "...." + $point + ":" + nth(map-get(map-get($gridMap, $point), width), 1);

			@include breakpoint($point) {
				width: str_length($content) * 6pt + $base-gap * 2;
			}
		}

		content: "#{$content}";
		display: none !important; /* Prevent from displaying. */
	}

	&.dev-mode:before,
	&.debug:before {
		background: $alert;
		border-color: $alert;
		border-style: solid;
		border-width: 0 rem($base-gap);
		color: $light;
		display: block !important;
		font-family: Courier;
		font-size: 10pt;
		left: 0;
		line-height: 2.5;
		overflow: hidden;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		white-space: nowrap;
		z-index: 99999;
	}
	&:hover:before {
		opacity: 0.5;
		pointer-events: none;
	}
}

// Animations
%animated-transform {
	transition: 0.3s;
}

html.in-progress {
	pointer-events: none;
	&:before {
		content:"";
		width: 100vw;
		height: 100vh;
		background:rgba(0,0,0,0.5);
		position: fixed;
		top:0;
		left:0;
		z-index:99999998
	}
	&:after {
		content:"";
		position: fixed;
		top: 0;
		height: 0;
		background: url('/images/layout/loading.svg') no-repeat center center;
		height: 100vh;
		width: 100vw;
		z-index:99999999;
		filter:invert(100%);
	}
}

// cms-4 grid system classes
.row {
	//vertical alignment
	&.stretch {
		align-items: stretch;
	}
	&.top {
		align-items: flex-start;
	}
	&.middle {
		align-items: center;
	}
	&.bottom {
		align-items: flex-end;
	}
	//horizontal alignment
	&.start {
		justify-content: flex-start;
	}
	&.center {
		justify-content: center;
	}
	&.end {
		justify-content: flex-end;
	}
	&.around {
		justify-content: space-around;
	}
	&.between {
		justify-content: space-between;
	}
}

.modal {
    top: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    transition: opacity .3s, visibility .3s;
    position: fixed;
    visibility: hidden;
    opacity: 0;
    background: rgba(black, 0.8);
    &.show-modal{
        opacity: 1;
        z-index: 1000000;
        visibility: visible;
    }
	body.cms-template-edit & {
		opacity: 1;
        z-index: 1000000;
        visibility: visible;
		height:auto;
	}
}
 
.close {
   position:absolute;
   right:rem(30px);
   top:rem(30px);
   color:$light;
   font-size:rem(50px);
   cursor: pointer;
   transition: opacity .3s, visibility .3s;

   body.cms-template-edit & {
	   display:none;
   }
}
 
.close:hover,
.close:focus {
    opacity: 0.7;
}
 
.modal-text{
    position:absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    width:70%;

	body.cms-template-edit & {
		position: relative;
		left:auto;
		top:auto;
		transform:none;
	}
}

#popUp {
	
	@include breakpoint(large, max) {
		padding:0;
		margin-bottom:rem(36px);
	}

	@include breakpoint(large) {
		padding-right:rem($base-gap);
		height:100%;

		body.cms-template-edit & {
			height:auto;
		}
	}
	@include breakpoint(giant) {
		padding-right:0;
	}
}

#btnCont {
	position: relative;
	cursor: pointer;

	@include breakpoint(large) {
		height:100%;

		body.cms-template-edit & {
			height:auto;
		}
	}

	&:before {
		content:"";
		display:block;
		width:100%;
		height:100%;
		position: absolute;
		background:rgba(#000,.4);
		@extend %animated-transform;

		body.cms-template-edit & {
			display:none;
		}
	}
	figure {
		@include breakpoint(large) {
			height:100%;

			body.cms-template-edit & {
				height:auto;
			}
		}
		img {
			margin-bottom:0;
			height:100%;
			object-fit: cover;

			body.cms-template-edit & {
				height:auto;
			}
		}
	}
	.picture-title {
		position: absolute;
		left:50%;
		top:50%;
		transform:translate(-50%,-50%);
		white-space: nowrap;
		font-family: $display-font;
		color:$light;
		@extend %animated-transform;
		pointer-events: none;
		margin-bottom:0 !important;
		display:flex;
		align-items: center;
		justify-content: center;

		body.cms-page-edit &,
		body.cms-template-edit & {
			pointer-events: all;
			position: relative;
			bottom:auto;
			left:auto;
			transform:none;
			color:$dark;
		}

		.icon-cont {
			width:rem(50px);
			height:rem(50px);
			background:$primary;
			display:flex;
			align-items: center;
			justify-content: center;
			border-radius:100%;
			position: relative;
			margin-right:rem(20px);

			@include breakpoint(large) {
				width:rem(70px);
			height:rem(70px);
			
			}

			@include breakpoint(huge) {
				margin-right:rem(67px);
				width:rem(91px);
				height:rem(91px);
			}

			body.cms-template-edit & {
				margin-right:0;
			}

			&:before {
				content:"";
				display:block;
				width:rem(60px);
				height:rem(60px);
				background:rgba($light,.5);
				position: absolute;
				top: 50%;
				left:50%;
				transform:translate(-50%,-50%);
				border-radius:100%;
				z-index:-1;

				@include breakpoint(large) {
					width:rem(90px);
					height:rem(90px);
				}

				@include breakpoint(huge) {
					width:rem(113px);
					height:rem(113px);
				}
			}

			i {
				font-weight: 400;
				font-size:rem(20px);
				font-style: normal;

				@include breakpoint(large) {
					font-size:rem(25px);
				}
			}
		}
	}

	&:hover,
	&:focus,
	&:active {
		&:before {
			background:rgba(#000,0);
		}
	}
}

[data-aos] {
	opacity:0;

	body.safari &,
	body.ios &,
	body.ipad & {
		opacity:1 !important;
		visibility:  visible !important;
		transform:none !important;
	}

	@include breakpoint(large, max) {
		opacity:1 !important;
		visibility:  visible !important;
		transform:none !important;
	}

	body.cms-page-edit &,
	body.cms-template-edit & {
		opacity:1;
	}

	&.aos-init {
		opacity:1;
	}
}

.popup-holder {
	opacity:0;
	pointer-events: none;

	body:not(.index):not(.index.cms-page-edit) & {
		display:none;
	}

	body.cms-page-edit & {
		opacity:1;
		pointer-events: all;
	}
}

.popUpContainer {

	.inner-wrap {
		background:$primary;
		color:$light;
	}
 
	body:not(.cms-page-edit):not(.cms-template-edit) & {
		top: 5vh;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 90vh;
		overflow: hidden;
		transition: opacity .3s, visibility .3s;
		position: fixed;
		visibility: visible;
		opacity: 1;
		z-index: 99;

		@include breakpoint(large) {
			height: 100vh;
			top:0;
		}
	 
		.popUpContent {
			width: 100%;
			background: $secondary;
			max-height: 80vh;
			max-width: calc(100% - #{rem(36px)}); // for the close label
			overflow: hidden;
			overflow-y: auto;
			font-family: $main-font;
			position: relative;
	 
			@include breakpoint(giant) {
				max-width: rem(1052px) !important;
			}

			.inner-wrap {
				@include breakpoint(small) {
					display:flex;
				}
			}
			.cms-article {
				font-size:rem($base-font-size);
				line-height: rem($base-line-height);
				font-weight: 300;
			}

			figure {
				text-align:center;
				height:rem(350px);
				width:100%;

				@include breakpoint(small) {
					width:38%;
					height:auto;
				}

				img {
					margin-bottom:0;
					height:100%;
					object-fit: cover;
				}
			}

			.text-container {
				padding:rem(30px) rem($base-gap);

				@include breakpoint(small) {
					padding:rem(30px) rem(36px);
				}

				@include breakpoint(giant) {
					padding:rem(97px) rem(77px) rem(62px) rem(77px);
				}
			}

			.backend-title {
				display:none;

				body.cms-page-edit &,
				body.cms-template-edit & {
					display:block;
				}
			}
		}
	}
	 
	label {
		display:none;
	 
		body:not(.cms-template-edit):not(.cms-page-edit) & {
			// background: rgba(black, 0.8);
			display: flex;
			height: 100%;
			justify-content: flex-end;
			padding: rem($base-gap);
			position: absolute;
			right: rem(10px);
			top: rem(10px);
			width: rem(74px);
			height:rem(74px);
			padding:0;
			z-index: 1;
			pointer-events: none;

			@include breakpoint(large) {
				right: rem(33px);
				top: rem(38px);
			}

			&.inside {
				background:transparent;
				width:rem(38px);
				height:rem(36px);
				padding:0;
				z-index:10;
				right: rem(20px);
				top: rem(20px);
				background:$primary;
				@extend %animated-transform;

				@include breakpoint(medium) {
					right: rem(61px);
					top: rem(31px);
				}

				i {
					width:rem(38px);
					height:rem(36px);
					transform:none !important;
					background:transparent;
					&:before,
					&:after {
						left:rem(4px);
						background:$light;
					}
					&:before {
						transform: rotate(45deg);
					}
					&:after {
						transform: rotate(-45deg);
					}
				}

				&:hover,
				&:focus,
				&:active {
					background:#000;
				}
			}
	 
			i {
				cursor: pointer;
				display: block;
				height: rem(24px);
				width: rem(24px);
				position: relative;
				transition: background .3s;
				pointer-events: all;
				background:$light;
				border-radius:100%;

				@include breakpoint(large) {
					height: rem(60px);
					width: rem(60px);
				}
	 
				&:hover {
					background: rgba(black, 0.8);
	 
					&:before, &:after {
						background: $light;
					}
				}
	 
				&:before, &:after {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					width: rem(16px);
					height: rem(2px);
					background: $dark;

					@include breakpoint(large) {
						width: rem(40px);
					}
				}
	 
				&:before {
					transform: translate(-50%,-50%) rotate(45deg);
				}
	 
				&:after {
					transform: translate(-50%,-50%) rotate(-45deg);
				}
			}
		}
		
	}
	}
	 
	input[id^="popUpToggled"] {
		display: none;
	 
		&:not(:checked) {
	 
			+ {
	 
			.popUpContainer {
				position: relative;
				opacity: 0;
				visibility: hidden;
				z-index: -1000000;
				transform:scale(0);
				pointer-events: none;
				width: 0;
				height: 0;
			}
		}
	}
}

body.sitemap {
	ul.sub {
		padding-left:rem(20px);
	}
}
