$amount: 5;
$iconBg: $primary;
$iconColor: $light;
$contactsBg: $primary;
$contactsColor: $light;
$desktopPosition: 200px;

//////////////////////////////////////////////////////////////// 

@import "../modules/fixedButtonsMixins";

.fixedContacts {
	>li {
		&:before {
			@include before;
			background: $iconBg;	
			color: $iconColor;
		}

		&.facebook {
			&:before {
				content:"";
				width:rem(16px);
				height:rem(16px);
				background-image:image-url('layout/fbook.svg');
				display:block;
				background-size: cover;
				background-position: center;
				max-width:none !important;

				@include breakpoint(large) {
					display:none;
				}
			}
		}

		&.instagram {
			&:before {
				content:"";
				width:rem(16px);
				height:rem(16px);
				background-image:image-url('layout/instagram.svg');
				display:block;
				background-size: cover;
				background-position: center;
				max-width:none !important;

				@include breakpoint(large) {
					display:none;
				}
			}
		}

		&.phone {
			&:before {
				content:"";
				width:rem(16px);
				height:rem(17px);
				background-image:image-url('layout/phone.svg');
				background-size:cover;
				background-position: center;

				@include breakpoint(large) {
					background:$light;
					width:rem(89px);
					height:rem(60px);
					z-index:100;
				}
			}
			.icon {
				z-index:101;
			}
		}

		&.email {
			&:before {
				content:"";
				width:rem(16px);
				height:rem(10px);
				background-image:image-url('layout/email.svg');
				background-size:cover;
				background-position: center;

				@include breakpoint(large) {
					background:$light;
					width:rem(89px);
					height:rem(60px);
					z-index:100;
				}
			}

			.icon {
				right:calc(100% + #{rem(30px)});
				z-index:101;
			}
		}

		&.hours {
			&:before {
				content:"";
				width:rem(16px);
				height:rem(16px);
				background-image:image-url('layout/clock.svg');
				background-size:cover;
				background-position: center;

				@include breakpoint(large) {
					background:$light;
					width:rem(89px);
					height:rem(60px);
					z-index:100;
				}
			}

			.icon {
				right:calc(100% + #{rem(30px)});
				z-index:101;
			}
		}

		.icon {
			@include breakpoint(large, max) {
				display:none;
			}

			position: absolute;
			right:calc(100% + #{rem(32px)});
		}

		// Icon Examples
		// &.phone {
		// 	&:before {
		// 		@extend .icon;
		// 		@extend .icon-phone;	
		// 	}
		// }

		// &.email {
		// 	&:before {
		// 		@extend .icon;
		// 		@extend .icon-envelope;
		// 	}
		// }

		// &.address {
		// 	&:before {
		// 		@extend .icon;
		// 		@extend .icon-marker;
		// 	}
		// }
	}

	// Mobile Styles
	@include breakpoint(large, max) {
		@include mobileFixedContacts;
		// overflow: visible;
		border-radius: 5px;
		// box-shadow: 6px -2px 15px rgba(0,0,0, .35);

		>li {
			@include mobileLi;
			background: $iconBg;
			cursor: pointer;
			overflow:visible;

			&:before {
				@include mobileBefore;
				cursor: pointer;
			}
			
			// Content on Hover/Tab
			.inner {
				@include mobileInner;					
				padding: rem(30px) rem($base-gap);
				box-shadow: 6px -2px 15px rgba(0,0,0, .35);
				background: $contactsBg;
				color: $contactsColor;
				
				.phone {
					display: block;
					height: 100%;
					width: auto;
				}
			}
			
			// Hover usally does not need to be adjusted
			&:hover {
				.inner {
					opacity: 1;
					visibility: visible;
					//transform: translate(0, -66px); // If the height of the bar is adjusted, you also need to adjust the transform
				}
			}

			&.hide {
				opacity: 0;
				transform: translate(0, -50px);
			}

			// Elements without Tab/Hover
			&.mobileNoHover {
				.inner {
					@include mobileNohoverInner;

					a {
						@include coverLink
					}
				}
			}
			
			&.mobileNoHover {
				// Define icon size e.g. social ícon
				&:before {
					max-width: 16px;
				}
			}
		}
	}

	// Break styles
	@include breakpoint(large) {
		@include desktopFixedContacts;
		top: $desktopPosition;

		@media screen and (max-height:850px) {
			top:rem(100px);
		}
		@media screen and (max-height:700px) {
			top:rem(50px);
		}
		@media screen and (max-height:650px) {
			top:0;
		}

		>li {
			@include desktopLi;
			background: $contactsBg;
			color: $contactsColor;
			padding: 0 rem(30px);
			font-size:rem(20px);
			
			body.internetExplorer & {
				height: 100%; 
				min-width: 360px; // Set min width for IE
			}

			&.address {
				body.internetExplorer & {
					height: auto; // For certain li that have multiple lines
				}
			}

			a {
				color:$light;

				&:hover,
				&:focus,
				&:active {
					color:$dark;
				}
			}

			@include nthOfType;
	
			.inner {
				white-space: nowrap; // Prevent the absolute content to be wrapped
				
			}

			&:before {
				@include desktopBefore;
				background: transparent;	
			}

			// Hover usally does not need to be adjusted
			&:hover {
				transform: none;
				// right: 0;
			}

			&:not(.noHover) {
				background: transparent;
				color: transparent;
				padding: 0;
				width:0;

				.svg-icon {
					path {
						transition:300ms stroke ease-in-out;
					}
				}

				.icon {
					cursor: pointer;
					transition:300ms opacity ease-in-out;
				}

				.inner {
					background: $contactsBg;
					color: $contactsColor;
					padding: 0 rem(40px);
					display:flex;
					align-items: center;
					justify-content: center;
					height:rem(60px);
					// position: absolute;
					// left:100%;
					opacity:0;
					visibility: hidden;
					transform: translateX(-100%);
					pointer-events: none;
					transition:300ms opacity ease-in-out, 300ms visibility ease-in-out, 300ms transform ease-in-out;
				}

				&:hover,
				&:focus,
				&:active {
					width:auto;
					.svg-icon {
						path {
							stroke:$primary;
						}
					}
					.inner {
						opacity:1;
						visibility: visible;
						transform: translateX(0);
						pointer-events: all;
					}
				}
			}

			&.noHover { // Remove or Set Element without hover
				@include desktopNoHover;
				background: transparent;
				body.internetExplorer & {
					min-width: auto;
				}

				a {
					display: flex;
					justify-content: center;
					align-items: center;

					&:hover,
					&:focus,
					&:active {
						opacity:.7;
					}
					
					// Define icon size e.g. social icon
					img {
						// max-width: 18px;
					}
				}
				
				// Removing mobile icon
				&:before {
					content: none;	
				}
			}
		}
		
	}
}