/**
 * Grid system
 */

.row {
	margin-left: auto;
	margin-right: auto;
	max-width: rem($rowMaxWidth);
	width: 100%;
	position: relative;
	display: flex;
	flex: 0 1 auto;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;

	&:not(.full-width) {
		.row {
			margin-left: -#{rem($base-gap)};
			padding-left: 0;
			padding-right: 0;
			max-width: calc(100% + #{rem($base-gap * 2)});
			width: calc(100% + #{rem($base-gap * 2)});
		}
	}
	&.full-width {
		max-width: none;
	}
	.cms-el-row {
		margin-left: -#{rem($base-gap)};
		padding-left: 0;
		padding-right: 0;
		max-width: calc(100% + #{rem($base-gap * 2)});
		width: calc(100% + #{rem($base-gap * 2)});
	}
	> .row {
		margin: 0;
		padding: 0;
		max-width: 100%;
		width: 100%;
		flex-basis: 100%;
	}

	&.break-right, 
	&.break-left {
		max-width: calc(50vw + #{$rowMaxWidth / 2 - 9});
	}
	&.break-right {
		margin-left: auto;
		margin-right: 0;
		justify-content: flex-start;
	}
	&.break-left {
		margin-right: auto;
		margin-left: 0;
		justify-content: flex-end;
	}
}

// Initialize grid
.col {
	float: left;
	padding-left: rem($base-gap);
	padding-right: rem($base-gap);
	max-width: 100%;
	width: 100%;
	flex: 0 0 auto;
	flex-grow: 1;
	flex-basis: 100%;
	box-sizing: border-box;
}

// remove padding left and right from cols
.no-gutter,
.no-gutter > .col {
	padding-left: 0;
	padding-right: 0;
}
.no-gutter-left {
	padding-left:0;
}
.no-gutter-right {
	padding-right:0;
}

@each $bp, $obj in $gridMap {
	@include breakpoint($bp) {
		@each $key, $val in $obj {
			@if $key == cols or $key == prefix or $key == suffix or $key == width {
				@each $elm in $val {
					@if $key == cols {
						.col.#{$bp}-#{$elm} {
							@if($elm != auto) {
								max-width: grid-size($elm, $column-count);
								flex-basis: grid-size($elm, $column-count);
								@include breakpoint(cut) {
									.row.break-left > &, .row.break-right > & {max-width: rem($rowMaxWidth / 12 * $elm);}
								}
							} @else {
								max-width: 100%;
								flex-basis: 0;
								.row.break-left > &, .row.break-right > & {max-width: 100%;}
							}
						}
					} @else if $key == suffix {
						.col.#{$bp}-suffix-#{$elm} {
							margin-right: grid-size($elm, $column-count);
						}
					} @else if $key == prefix {
						.col.#{$bp}-prefix-#{$elm} {
							margin-left: grid-size($elm, $column-count);
						}
					}
				}
			} @else {
				.#{$bp}-#{$key} {
					@each $elm in $val {
						#{nth($elm, 1)}: #{nth($elm, 2)};
					}
				}
			}
		}
	}
}
