* {
	margin: 0;
	padding: 0;

	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
}

::selection {
	background:$primary;
	color:$light;
}
::-moz-selection {
	background:$primary;
	color:$light;
}

// DO NOT set font-size and line-height here!
// Adjust $base-font-size and $base-line-height in _config.scss
html {
	background: $light;
	box-sizing: border-box;
	color: $dark;
	font-family: $main-font;
	font-size: 100% * ($base-font-size / 16px);
	hyphens: manual;
	line-height: rem($base-line-height);
	scroll-behavior: smooth;
	

	/* disable text resize in landscape. e.g. on iphone */
	text-size-adjust: none;
}

body {
	line-height: rem($base-line-height);
	opacity: 1 !important;
}

iframe,
[data-iframe] {
	border: none;
	width: 100%;
}

address {
	font-style:normal;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	@extend .margin-bottom;
	display: block;
	text-rendering: optimizeLegibility;
	hyphens: manual;
	color:$secondary;
	font-family: $display-font;
	font-weight: 400;

	.highlight {
		color:$primary;
	}

	/* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/

	small {
		display:block;
		font-family: $main-font;
		color:#000;
		font-weight: bold;
		font-size:rem(20px);
		line-height: rem(24px);

		@include breakpoint(medium) {
			font-size:rem(24px);
			line-height: rem(30px);
		}

		@include breakpoint(giant) {
			font-size:rem(30px);
		line-height: rem(36px);
		}
	}

	.highlight-block &,
	.popUpContent & {
		color:$light;
	}
}

h1,
.h1 {
	font-family: $display-font;
	font-size: rem(28px);
	line-height: rem(36px);

	@include breakpoint(medium) {
		font-size:rem(42px);
		line-height:rem(48px);
	}

	@include breakpoint(giant) {
		font-size: rem($h1-size);
		line-height: rem(65px);
		margin-bottom:rem(56px) !important;
	}
}

h2,
.h2 {
	font-family: $main-font;
	font-size: rem(28px);
	line-height: rem(36px);
	font-weight: bold;

	@include breakpoint(medium) {
		font-size:rem(42px);
		line-height:rem(48px);
	}

}

h3,
.h3 {
	font-size: rem(26px);
	font-family: $main-font;
	font-weight: bold;

	@include breakpoint(medium) {
		font-size: rem(36px);
		line-height: rem(46px);
	}

}

h4,
.h4 {
	font-size: rem(26px);
	font-family: $main-font;
	font-weight: bold;

	body.datenschutzerklaerung & {
		margin-bottom: rem(10px) !important;
	  }

	@include breakpoint(medium) {
		font-size: rem(32px);
		line-height: rem(42px);
	}

}

h5,
.h5 {
	font-size: rem(24px);
	font-family: $main-font;
	font-weight: bold;

	@include breakpoint(medium) {
		font-size: rem(26px);
		line-height: rem(38px);
	}


	body.datenschutzerklaerung & {
		margin-bottom: rem(10px) !important;
	  }
}

h6,
.h6 {
	font-size: rem(20px);
	font-family: $main-font;
	font-weight: bold;

	@include breakpoint(medium) {
		font-size: rem(22px);
		line-height: rem(32px);
	}

	body.datenschutzerklaerung & {
		margin-bottom: rem(10px) !important;
	  }
}

/**
 * Links
 */
a {
	color: $primary;
	word-wrap: break-word;
	@extend %animated-transform;
	text-decoration: none;

	.highlight-block & {
		color:$light;
		border-bottom:1px solid $light;
	}

	&:hover,
	&:focus,
	&:active {
		color: $dark;

		.highlight-block & {
			color:$light;
			border-bottom:1px solid transparent;
		}
	}

	img {
		border: none;
	}

	&[href^="tel"] {
		color: inherit;
		text-decoration: none;
	}
}

a:not(.btn):focus,
[tabindex]:focus {
	outline: $outline;
	outline-offset: rem(5px);
}

hr,
.hr {
	display: flex;
	background: $medium;
	border: none;
	clear: both;
	height: rem(1px);
	margin: 1.5rem 0 3rem;
	flex-basis: 100%;
}

ul,
ol {
	list-style: none;

	&.styled-list {
		li {
			padding-left: rem(20px);
			margin-bottom: rem(7px);

			&:before {
				@extend .icon;
				@extend .icon-angle-right;
				text-indent: rem(-20px);
				font-size: rem(16px);
			}
		}

		ul {
			margin-left: 1rem;
			margin-bottom: 0;
		}
	}
}

dl {
	&.styled-list {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			background: rgba($medium, 0.8);
			padding: rem(15px);
		}

		dt {
			padding-bottom: 0;
			font-weight: bold;
		}

		dd {
			padding-top: 0;

			+ dt {
				margin-top: rem(10px);
			}

			+ dd {
				margin-top: rem(-15px);
			}
		}
	}

	&.float-list {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			vertical-align: top;
			float: left;
		}

		dt {
			padding-right: 0.5rem;
			width: 40%;
			clear: left;
		}

		dd {
			width: 60%;
		}
	}
}

ol {
	&.styled-list
	{
		counter-reset: item;
	
		li {
			counter-increment: item;
	
			&:before {
				content: counters(item, ".") ". ";
			}
		}
	
		ol {
			margin-bottom: 0;
	
			li {
				&:before {
					content: counters(item, ".") " ";
					margin-right: rem(5px);
				}
			}
		}
	}
}

/*
 * responsive images
 */

img {
	vertical-align: bottom;
	float: none;
	height: auto;
	max-width: 100%;
	width: 100%;

	[data-rel] &,
	.lightbox-image & {
		margin-bottom: 0;
	}

	&[data-src] {
		@extend %lazy-loading;
	}
}

/**
 * Tables
 */
main {
	table {
		display: table;
		border-collapse: collapse;
		width: 100%;
		max-width: 100%;
		text-align: left;
		font-size: rem($base-font-size);

		@include breakpoint(large, max) {
			overflow-x: auto;
			display: block;
		}

		caption {
			text-align: left;
			padding: rem($base-gap) rem($base-gap) rem(25px);
			display: table-caption;
			font-weight: bold;
			font-size: 1.2rem;
		}

		thead {
			border-collapse: collapse;
			display: table-header-group;
		}

		tbody {
			width: 100%;
			overflow-x: scroll;
			display: table-row-group;

			tr {
				padding: rem($base-gap) 0;

				&:not(:last-child) {
					border-bottom: rem(1px) solid $medium;
				}
			}
		}

		tr {
			display: table-row;
		}

		th,
		td {
			padding: 1.2rem;
			display: table-cell;
			vertical-align: top;
		}

		@include breakpoint(large, max) {
			td {
				min-width: 50vw;
			}
		}
	}
}
