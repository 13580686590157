// PAGE WRAP
//////////////////////////////
#page-wrap {
	
	@include breakpoint(large) {
		padding-left:rem(90px);
	}

	body.cms-template-edit &,
	body.cms-page-edit & {
		padding-left:0;
	}
}

// SIDEBAR
//////////////////////////////
#sidebar {
	width:rem(89px);
	height:100vh;
	background:$light;
	position: fixed;
	left:0;
	top:0;
	z-index:102;
	border-right:1px solid $border;

	@include breakpoint(large, max) {
		background:transparent;
		position: fixed;
		left:0;
		top:0;
		border:none;
		height:0;
		z-index:1001;
	}
	img {
		width:rem(29px);
		height:rem(29px);
	}

	body.cms-template-edit &,
	body.cms-page-edit & {
		display:none;
	}
}
.teamContainer {
	margin-bottom:3rem;
	h3 {
		@include breakpoint(giant) {
			margin-bottom: 3rem;
		}
	}
}
.auto-width {
	img {
		width: auto;
	}
}
// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;
	background:$medium;
	height:rem(680px);

	body.sub-template & {
		height:rem(195px);
	}

	@include breakpoint(large) {
		padding-top:rem(100px);
		height:rem(900px);

		body.sub-template & {
			height:rem(152px);
		}
	
	}


	@include breakpoint(giant) {
		height:rem(1045px);

		body.sub-template & {
			height:rem(213px);
		}
	}
	@include breakpoint(huge) {
		body.sub-template & {
			height:rem(233px);
		}
	}
	body.cms-template-edit & {
		height:auto !important;
	}
	.branding {
		display: inline-block;
		img {
			mix-blend-mode: multiply;
		}
	}

	.top-line {
		padding-top:rem(70px);
		padding-bottom:rem(40px);
		@extend %animated-transform;
		text-align:center;

		body.sub-template & {
			padding-bottom:rem(20px);
		}

		@include breakpoint(small) {
			padding-bottom:0;
			
			body.sub-template & {
				padding-bottom:rem(60px);
			}
		}

		@include breakpoint(large) {
			position: fixed;
			top:0;
			left:rem(90px);
			width:calc(100% - #{rem(90px)});
			z-index:100;
			background:$medium;
			padding-top:rem(50px);
			
			text-align:left;
		}

		@include breakpoint(giant) {
			padding-top:rem(64px);
		}

		body.cms-template-edit & {
			left:auto;
			top:auto;
			position:relative;
		}

		body.sticky & {

			@include breakpoint(large) {
				padding-top:rem(20px);
				padding-bottom:rem(20px);
				box-shadow: $base-box-shadow;
			}
		}
	}

	.slider-inner-wrap {
		position: relative;
		height: rem(460px) !important;

		body.cms-template-edit & {
			height:auto !important;
		}
		
		@include breakpoint(large, max) {
			overflow: hidden;
		}
		@include breakpoint(large) {
			height: rem(800px) !important;
		}
		@include breakpoint(giant) {
			height: rem(945px) !important;
		}

		@include breakpoint(large) {
			&:before {
				content:"";
				display:block;
				width:rem(15px);
				height:rem(15px);
				background:$primary;
				border-radius:50%;
				bottom:rem(176px);
				position: absolute;
				left:rem(243px);
				z-index:11;
	
				body.cms-template-edit & {
					display:none;
				}
			}
	
			&:after {
				content:"";
				height:rem(276px);
				width:1px;
				background:$border;
				position: absolute;
				bottom:rem(-90px);
				left:rem(250px);
				display:block;
				z-index:10;
	
				body.cms-template-edit & {
					display:none;
				}
			}
		}
	}

	.header-slider {
		height: rem(460px) !important;
		@include breakpoint(large) {
			height: rem(800px) !important;
		}
		@include breakpoint(giant) {
			height: rem(945px) !important;
		}
		

		body.cms-template-edit & {
			height:auto !important;
		}

		.swiper-slide {
			&.slide03 {
				.bg-img {
					.slider-img {
						
						@include breakpoint(small) {
							right:0;
						}
					}
				}
			}
		}

		.bg-img {
			position: relative;
			height: rem(460px);
			background:$medium;

			@include breakpoint(large) {
				height:rem(800px);
			}

			@include breakpoint(giant) {
				height:rem(945px);
			}

			body.cms-template-edit & {
				height:auto;
			}
			.background-text {
				display:none;				

				@include breakpoint(small) {
					position: absolute;
					top:rem(50px);
					left: 0;
					text-align:center;					
					color:$light;
					font-family: $display-font;
					font-size:rem(60px);
					line-height: rem(60px);
					white-space: nowrap;
					display:block;
					width:100%;
				}

				@include breakpoint(medium) {
					font-size:rem(100px);
					line-height: rem(100px);
				}

				@include breakpoint(large) {
					top:rem(180px);
					padding-left:rem(30px);
					font-size:rem(120px);
					line-height: rem(120px);
					text-align:left;
					width:auto;
				}

				@include breakpoint(giant) {
					font-size:rem(150px);
					line-height: rem(150px);
				}

				@include breakpoint(full) {
					
					font-size:rem(200px);
					line-height: rem($base-line-height);
					top:rem(315px);
					padding-left:rem(100px);
				}

				@include breakpoint(cut) {
					font-size:rem(220px);
				}
				

				body.cms-template-edit & {
					position: relative;
					top:auto;
					left:auto;
					transform:none;
					line-height: rem(220px);
					color:$dark;
					font-size:rem(100px);
				}
			}

			.claim-container {
				text-align:center;
				width:100%;
				
				z-index:100;
				

				@include breakpoint(small) {
					bottom:rem(100px);
					position: absolute;
					
					text-align:left;
				}

				@include breakpoint(large) {
					padding-left:rem(30px);
					bottom:rem(257px);
				}

				@include breakpoint(full) {
					padding-left:rem(114px);
				}

				body.cms-template-edit & {
					position: relative;
					bottom:auto;
				}

				.claim-text {
					color:$secondary;
					font-size:rem(24px);
					line-height: rem(32px);
					font-family: $display-font;
					margin-bottom:rem(20px);

					body.cms-template-edit & {
						font-size:rem(40px);
					}

					@include breakpoint(small) {
						font-size:rem(32px);
						line-height: rem(40px);
					}
					@include breakpoint(medium) {
						font-size:rem(42px);
						line-height: rem(56px);
					}

					@include breakpoint(giant) {
						font-size:rem(56px);
						line-height: rem(56px);
					}

					@include breakpoint(huge) {
						font-size:rem(65px);
						line-height: rem(65px);
						margin-bottom:rem(45px);
					}
				}
				
				.cms-button {
					display:inline-block;
				}
			}

			.slider-img {
				@include breakpoint(huge, max) {
					img {
						height:rem(800px);
					}
				}
				@include breakpoint(giant, max) {
					img {
						height:rem(700px);
					}
				}
				@include breakpoint(large, max) {
					text-align:center;
					margin-top:rem(30px);
					img {
						height:rem(300px);

						@include breakpoint(small) {
							height:rem(400px);
						}
					}
				}
				@include breakpoint(small) {
					position: absolute;
					bottom:0;
					right:rem(50px);
				}
				@include breakpoint(giant) {
					right:rem(120px);
				}
				@include breakpoint(huge) {
					right:rem(200px);
				}
				@include breakpoint(full) {
					right:rem(250px);
				}

				body.cms-template-edit & {
					position: relative;
					bottom:auto;
					right:auto;
				}

			}
		}

		.swiper-pagination {
			display:flex;
			justify-content: center;
			bottom:0;
			position: absolute;			

			@include breakpoint(large) {
				flex-direction: column;
				right:rem(5px);
				left:auto;
				width:auto;
				bottom:auto;
				top:50%;
				transform:translateY(-50%);
				justify-content: flex-start;
			}
 
			body.cms-template-edit & {
				display:none;
			}
			.swiper-pagination-bullet {
				width:rem(7px);
				height:rem(7px);
				background:#C4C4C4;
				position: relative;
				opacity:1;
				margin-bottom:rem(20px);
				margin-right:rem(15px);

				@include breakpoint(large) {
					margin-right:0;
				}

				&:before {
					content:"";
					display:block;
					width:rem(15px);
					height:rem(15px);
					background:$primary;
					left:50%;
					top:50%;
					transform:translate(-50%,-50%);
					border-radius:50%;
					position: absolute;
					opacity:0;
					@extend %animated-transform;
				}

				&:hover,
				&:focus,
				&:active,
				&.swiper-pagination-bullet-active {
					&:before {
						opacity:1;
					}
				}
			}
		}
	}
	body.cms-page-edit & .header-slider .swiper-wrapper {
		overflow: visible !important;
	}
	body.cms-page-edit & .header-slider .swiper-pagination {
		position: relative !important;
		bottom: unset !important;
		left: unset !important;
		transform: translateX(0%) !important;
	}

	.google-link {
		font-size:rem(18px);
		line-height: rem(28px);
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: 1px;
		color:#000;
		position: absolute;
		bottom:rem(-43px);
		right:rem(8px);
		z-index:10;
		writing-mode: vertical-lr;

		@include breakpoint(small) {
			bottom:rem(-83px);
		}

		@include breakpoint(large) {
			bottom:rem(-43px);
		}

		body.cms-template-edit & {
			bottom:auto;
			right:auto;
			position: relative;
			writing-mode: horizontal-tb;
		}

		&:hover,
		&:focus,
		&:active {
			color:$primary;
		}
	}
	
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	
	p,
	ol,
	ul,
	dl,
	table {
		@extend .margin-bottom ;
	}

	ul:not(.unstyled-list),
	dl:not(.unstyled-list),
	ol:not(.unstyled-list) {
		@extend .styled-list;
	}

	img {
		margin-bottom:rem($base-gap*2);
	}

	.section {

		&.section-dark {
			background:$medium;
		}
		
		&.section-teaser {
			padding-top:rem(50px);
			padding-bottom:rem(20px);
			text-align:center;

			@include breakpoint(small) {
				padding-top:rem(100px);
				padding-bottom:rem(80px);
			}

			@include breakpoint(large) {
				text-align:left;
				padding-top:rem(152px);
				padding-bottom:rem(120px);
			}

			.h1 {
				@include breakpoint(giant) {
					padding-left:rem(73px);
				}
			}
			.swiper-slide {
				width: rem(250px);

				@include breakpoint(small) {
					width: rem(360px);
				}

				@include breakpoint(giant) {
					width: rem(507px);
				}
			}

			.swiper-navigation {
				display:flex;
				justify-content: center;
				margin-bottom:rem(30px);

				@include breakpoint(large) {
					left:rem(420px);
					position: absolute;
					top:0;
					justify-content: flex-start;
					margin-bottom:0;
				}

				@include breakpoint(giant) {
					left:rem(563px);
				}

				body.cms-page-edit & {
					display:none;
				}

				.swiper-button-prev,
				.swiper-button-next {
					top:auto;
					bottom:auto;
					transform: none;
					margin:0;
					left:auto;
					right:auto;
					width:auto;
					height:auto;
					background:none;
					opacity:1;
					

					&.swiper-button-disabled {
						opacity:.7;
					}

					&:before {
						font-size:rem(18px);
						color:$primary;
						width:rem(63px);
						height:rem(63px);
						border-radius:50%;
						background:#F2E6E8;
						display:flex;
						align-items: center;
						justify-content: center;
						font-weight: bold;
						@extend %animated-transform;
					}

					&:hover,
					&:focus,
					&:active {
						&:before {
							color:$dark;
							background:rgba($primary,.3);
						}
					}
				}

				.swiper-button-prev {
					position: relative;
					margin-right:rem(18px);
					&:before {
						content:map-get($icon-map, angle-left);
						font-family: $icon-font;

					}
				}

				.swiper-button-next {
					position: relative;
					&:before {
						content:map-get($icon-map, angle-right);
						font-family: $icon-font;

					}
				}
			}

			.teaser-slider {

				.slide-title {
					font-size:rem(20px);
					line-height: rem(30px);
					font-weight: bold;
					display:flex;
					flex-direction: column;
					align-items: center;
					margin-top:rem(40px);
					margin-left:rem(100px);
					position: relative;
					color:#000;
					@extend %animated-transform;

					@include breakpoint(large) {
						font-size:rem(30px);
					line-height: rem(36px);
					margin-top:rem(96px);
					}

					&:before {
						content:"";
						display:block;
						width:1px;
						height:rem(75px);
						background:$border;
						position: absolute;
						left:calc(50% - #{rem(50px)});
						transform:translateX(-50%);
						bottom:calc(100% + #{rem(19px)});

						@include breakpoint(large) {
							height:rem(154px);
						}
					}

					.highlight {
						display:block;
						font-size:rem(28px);
						font-family: $display-font;
						font-weight: 400;
						margin-left:calc(-50% - #{rem(18px)});

						@include breakpoint(large) {
							font-size:rem(50px);
						}
					}
				}
				figure {
					margin-bottom:rem(36px);
					position: relative;
					img {
						margin-bottom:0;
					}

					&:before {
						content:"Mehr erfahren";
						display:flex;
						align-items: center;
						justify-content: center;
						width:100%;
						height:100%;
						left:0;
						top:0;
						background:rgba(#000,.45);
						position: absolute;
						color:$light;
						font-size:rem(30px);
						line-height: rem(36px);
						font-family: $display-font;
						@extend %animated-transform;
						opacity:0;

						@include breakpoint(large) {
							font-size:rem(50px);
							line-height: rem(56px);
						}
					}
				}
				.swiper-slide {

					&:hover,
					&:focus,
					&:active {
						.slide-title {
							opacity:0;

							body.cms-page-edit & {
								opacity:1;
							}
						}
						figure {
							&:before {
								opacity:1;

								body.cms-page-edit & {
									opacity:0;
								}
							}
						}
					}
				}
			}
		}

		&.section-team {
			background:$medium;
			padding-top:rem(50px);
			padding-bottom:rem(15px);

			@include breakpoint(small) {
				padding-top:rem(100px);
				padding-bottom:rem(70px);
			}

			@include breakpoint(large) {
				padding-top:rem(170px);
				padding-bottom:rem(137px);
			}

			.team-container {
				position: relative;

				&:before {
					content:"Leidenschaft";
					color:$light;
					font-size: rem(60px);
					line-height: rem(60px);
					font-family: $display-font;
					text-align:center;
					display:block;
					margin-bottom:rem(-20px);

					@include breakpoint(small) {
						font-size:rem(80px);
						line-height: rem(80px);
					}

					@include breakpoint(large) {
						font-size: rem(120px);
					line-height: rem(120px);
					margin-bottom:rem(-40px);
					}

					@include breakpoint(giant) {
						font-size: rem(220px);
						line-height: rem(220px);
						margin-bottom:rem(-78px);
					}
				}
			}

			.swiper-navigation {
				display:flex;
				justify-content: center;
				margin-top:rem(10px);

				@include breakpoint(large) {
					display:none;
				}

				body.cms-page-edit & {
					display:none;
				}

				.swiper-button-prev,
				.swiper-button-next {
					top:auto;
					bottom:auto;
					transform: none;
					margin:0;
					left:auto;
					right:auto;
					width:auto;
					height:auto;
					background:none;
					opacity:1;
					

					&.swiper-button-disabled {
						opacity:.7;
					}

					&:before {
						font-size:rem(18px);
						color:$primary;
						width:rem(63px);
						height:rem(63px);
						border-radius:50%;
						background:#F2E6E8;
						display:flex;
						align-items: center;
						justify-content: center;
						font-weight: bold;
						@extend %animated-transform;
					}

					&:hover,
					&:focus,
					&:active {
						&:before {
							color:$dark;
							background:rgba($primary,.3);
						}
					}
				}

				.swiper-button-prev {
					position: relative;
					margin-right:rem(18px);
					&:before {
						content:map-get($icon-map, angle-left);
						font-family: $icon-font;

					}
				}

				.swiper-button-next {
					position: relative;
					&:before {
						content:map-get($icon-map, angle-right);
						font-family: $icon-font;

					}
				}
			}

			.team-slider {
				.swiper-slide {
					position: relative;

					.picture-container {
						position: relative;
						margin-bottom:rem(36px);

						figure {
							img {
								margin-bottom:0;
								

								@include breakpoint(cut) {
									min-height:rem(254px);
								}
							}
						}

						.picture-info {
							position: absolute;
							background:rgba($primary,.9);
							width:100%;
							bottom:0;
							left:0;
							text-align:center;
							color:$light;
							font-size:rem(18px);
							line-height: rem(28px);
							padding-top:rem(15px);
							padding-bottom:rem(16px);
							opacity:0;
							visibility: hidden;
							@extend %animated-transform;
							pointer-events: none;

							p {
								margin-bottom:0;
							}
						}
					}

					.member-name {
						text-align:center;
						color:$primary;
						font-size:rem(20px);
						line-height: rem(28px);
						position: relative;
						margin-top:rem(72px);

						&:before {
							content:"";
							display:block;
							width:1px;
							height:rem(53px);
							background:rgba(#000,.3);
							position: absolute;
							bottom:calc(100% + #{rem(20px)});
							left:50%;
							transform:translateX(-50%);
						}
					}

					&:hover,
					&:focus,
					&:active {
						.picture-container {
							.picture-info {
								opacity:1;
								visibility: visible;
							}
						}
					}
				}
			}

			.claim-text {
				color:$secondary;
				font-size:rem(30px);
				line-height: rem(36px);
				font-family: $display-font;
				margin-top:rem(30px);

				@include breakpoint(small) {
					font-size:rem(48px);
					line-height: rem(48px);
				}

				@include breakpoint(large) {
					font-size:rem(65px);
				line-height: rem(65px);
				margin-top:rem(77px);
				}
			}
		}

		&.section01 {
			padding-top:rem(50px);
			padding-bottom:rem(26px);

			@include breakpoint(small) {
				padding-top:rem(100px);
				padding-bottom:rem(86px);
			}

			@include breakpoint(large) {
				padding-top:rem(70px);
				padding-bottom:rem(86px);
			}

			.content-img01 {
				margin-top:rem(20px);

				@include breakpoint(small) {
					margin-top:rem(80px);
				}

				@include breakpoint(large) {
					margin-top:rem(160px);
				}
			}

			h1 {
				small {
					
					@include breakpoint(huge) {
						margin-top:rem(25px);
						padding-left:rem(90px);
					}
				}
			}
		}

		&.section02 {
			padding-top:rem(50px);
			padding-bottom:rem(24px);

			@include breakpoint(small) {
				padding-top:rem(100px);
				padding-bottom:rem(74px);
			}

			@include breakpoint(large) {
				padding-top:rem(150px);
			padding-bottom:rem(124px);
			}

			h2 {
				

				@include breakpoint(huge) {
					margin-bottom:rem(81px) !important;
					padding-left:rem(58px);
				}

				small {
					margin-bottom:rem(17px);
					

					@include breakpoint(huge) {
						margin-left:rem(-58px);
					}
				}
			}
		}

		
	}
}

.s-h {
	body:not(.index) & {
	  display: none; // todo
	}
  }
  
  .sub-section {
	&:first-child {
	  padding-top: rem(50px);

	  @include breakpoint(large) {
		padding-top: rem(120px);
	  }
	}
	& + .sub-section {
	  position: relative;
	  padding-top: rem(50px);
	  padding-bottom: rem(50px - $base-line-height);
	  @include breakpoint(large) {
		padding-top: rem(120px);
		padding-bottom: rem(120px - $base-line-height);
	  }
	}
	&:nth-child(2) {
	}
	&:last-child {
	  padding-bottom: rem(50px - $base-line-height);
	  @include breakpoint(large) {
		padding-bottom: rem(120px - $base-line-height);
	  }
	}
	&:nth-child(odd):not(&:first-child) {
	  background-color: #f1f1f1;
	}
  
	.cms-container-el {
	  &.row {
		display: flex !important;
	  }
	}
  
	// kundenmeinungen
	.review-area {
	  padding: rem($base-line-height);
	  box-shadow: 0px 0px 4px 2px rgba($dark, 0.22);
	  background-color: $light;
	  margin-bottom: rem($base-line-height);
	  @include breakpoint(large) {
		padding: rem(45px);
	  }
	  .headline {
		p {
		  margin-bottom: rem($base-gap);
		}
		strong {
		  font-size: rem(18px);
		  line-height: 1.363em;
		  font-weight: 500;
		  color: $primary;
		  @include breakpoint(large) {
			font-size: rem(20px);
		  }
		  @include breakpoint(huge) {
			font-size: rem(22px);
		  }
		}
	  }
	  body:not(.cms-page-edit) & {
		.star-area {
		  margin-bottom: rem($base-gap);
		  &:not(.render) {
			display: none;
		  }
		  i {
			font-size: rem(24px);
			line-height: 1em;
			color: $primary;
			@include breakpoint(large) {
			  font-size: rem(28px);
			}
			@include breakpoint(huge) {
			  font-size: rem(36px);
			}
		  }
		}
	  }
	  body.cms-page-edit & {
		.stars {
		  p {
			position: relative;
			&:before {
			  content: "Bitte geben Sie die Anzahl der Sterne ein:";
			  display: inline-block;
			  color: rgba($dark, 0.65);
			  cursor: not-allowed;
			  margin-right: rem(6px);
			}
		  }
		}
	  }
	}
	// --kundenmeinungen
	// impressionen
	// .custom-gallery {
	//   .modalGallery {
	// 	> .cms-gallery-image {
	// 	  max-width: 100% !important;
	// 	  flex-basis: 100% !important;
	// 	}
	//   }
	//   @include breakpoint(tiny) {
	// 	.modalGallery {
	// 	  > .cms-gallery-image {
	// 		max-width: 50% !important;
	// 		flex-basis: 50% !important;
	// 	  }
	// 	}
	//   }
	//   @include breakpoint(small) {
	// 	.modalGallery {
	// 	  > .cms-gallery-image {
	// 		max-width: 33.3333% !important;
	// 		flex-basis: 33.3333% !important;
	// 	  }
	// 	}
	//   }
	//   @include breakpoint(giant) {
	// 	.modalGallery {
	// 	  > .cms-gallery-image {
	// 		max-width: 25% !important;
	// 		flex-basis: 25% !important;
	// 	  }
	// 	}
	//   }
	// }
	.gallery-area {
	  min-height: auto;
	}
	//--impressionen
  
	// veranstaltungen
	.ver-box {
	  padding: rem($base-line-height);
	  box-shadow: 0px 0px 4px 2px rgba($dark, 0.22);
	  background-color: $light;
	  margin-bottom: rem($base-line-height);
	  @include breakpoint(large) {
		padding: rem(60px) rem(70px);
	  }
	}
	//--veranstaltungen
  
  
	//table tanzkurse
	.table-area {
	  table {
		thead {
		  background-color: $primary;
		  color: $light;
		  font-weight: 700;
		}
		tbody {
		  tr {
			&:nth-child(odd) {
			  background-color: $light;
			}
			&:nth-child(even) {
			  background-color: rgba($primary, 0.2);
			}
			td {
			  vertical-align: middle;
			  &:last-child {
				text-align: right;
				.btn {
				  color: $primary;
				  text-transform: uppercase;
				  margin-bottom: 0;
  
				  &:hover,
				  &:active,
				  &:focus {
					color: $dark;
				  }
				}
			  }
			}
		  }
		}
	  }
	}
	//--table tanzkurse
  }  

#privacy-policy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}



// FOOTER
//////////////////////////////

#footer {
	
	.section {
		&.section-dark {
			background:$medium;
		}
		&.section-video {
			padding-top:rem(50px);
			padding-bottom:rem(50px);

			@include breakpoint(small) {
				padding-top:rem(100px);
				padding-bottom:rem(100px);
			}

			@include breakpoint(large) {
				padding-top:rem(147px);
				padding-bottom:rem(160px);
			}

			.highlight-block {
				background:$primary;
				color:$light;
				height:100%;
				display:flex;
				align-items: center;
				padding:rem(50px) rem($base-gap);

				@include breakpoint(small) {
					padding:rem(50px);
				}

				@include breakpoint(large) {
					padding:rem(50px) rem($base-gap);
				}
				@include breakpoint(huge) {
					padding:rem(50px) rem(30px);
				}
				@include breakpoint(full) {
					padding:rem(50px) rem(82px);
				}

				p {
					&:last-of-type {
						margin-bottom:0;
					}
				}

				h4 {
					@include breakpoint(large) {
						margin-bottom:rem(38px) !important;
					}
				}
			}
		}

		&.section-newsletter {
			text-align:left;

			@include breakpoint(large) {
				text-align:left;
			}
			
			.section-title {
				color:$light;
				font-size:rem(60px);
				line-height: rem(60px);
				font-family: $display-font;

				@include breakpoint(medium) {
					font-size:rem(100px);
					line-height: rem(100px);
				}

				@include breakpoint(large) {
					font-size:rem(120px);
					line-height: rem(120px);
				}

				@include breakpoint(giant) {
					font-size:rem(150px);
					line-height: rem(150px);
				}

				@include breakpoint(full) {
					font-size:rem(200px);
					line-height: rem(200px);
				}

				@include breakpoint(cut) {
					font-size:rem(220px);
					line-height: rem(220px);
					padding-left:rem(135px);
					padding-bottom:rem(25px);
				}
			}

			.newsletter-title {
				p {
					margin-bottom:0;
				}

				@include breakpoint(large) {
					margin-bottom:rem(46px) !important;
				}
			}

			form {
				label:not([for="Datenschutz"]) {
					display: none;
				}

				button {
					@include breakpoint(small, max) {
						margin-top:rem(30px);
					}

					@include breakpoint(large) {
						float:left;
					}
				}

				.checkbox {
					display: flex;
					padding: 1rem 0 0.6rem;

					[for="Datenschutz"] {
						padding: 0;
					}

					[type="checkbox"] {
						margin-top: 0.2rem;
					}
				}
			}

			.inner-wrap {
				position: relative;
				padding-top:rem(50px);
				padding-bottom:rem(40px);

				@include breakpoint(small) {
					padding-top:rem(100px);
					padding-bottom:rem(100px);
				}

				@include breakpoint(large) {
					padding-top:rem(188px);
					padding-bottom:rem(155px);
				}
				&.lazy-loaded {
					&:before {

						@include breakpoint(large) {
							content:"";
							display:block;
							width: rem(461px);
							height: rem(676px);
							background-image:image-url('layout/newsletter-img.png');
							background-size:cover;
							background-position: center;
							position: absolute;
							right:0;
							top:rem(74px);
							mix-blend-mode: darken;
						}

						@include breakpoint(huge) {
							right:rem(130px);
						}
					}
				}
			}
		}

		&.section-partner {
			padding-top:rem(50px);
			padding-bottom:rem(14px);
			body:not(.index) & {
				border-top:1px solid rgba($dark,0.3);
			}
				
			@include breakpoint(small) {
				padding-top:rem(75px);
				padding-bottom:rem(75px);
			}

			.inner-wrap {
				display:flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;

				@include breakpoint(large) {
					justify-content: space-between;
				}

				figure {
					padding-left:rem($base-gap);
					padding-right:rem($base-gap);
					margin-bottom:rem(36px);

					@include breakpoint(large) {
						padding-left:0;
						padding-right:0;
						margin-bottom:0;
					}
				}
			}
		}

		&.section-map {
			iframe {
				display:block;
				height:rem(300px);
	
				@include breakpoint(large) {
					height:rem(634px);
				}
			}
		}
	}
	

	.cms-navi {
		padding-top:rem(50px);
		padding-bottom:rem(90px);

		@include breakpoint(medium) {
			padding-top:rem(67px);
		}
	}
}

body.kundenmeinungen {
	.sub-section:nth-child(2n+1):not(.sub-section:first-child) {
		.btn {
			margin-bottom:3rem;
		}
	}
}

body.philosophie {
	.sub-section {
		&:first-child {
			padding-top:4.5rem;
			background:url(../images/layout/bgPhilosophie.jpg);
			background-size: cover;
			background-position:bottom center;
			padding-bottom: 1.5rem;
			position: relative;
			@include breakpoint(large) {
				padding-top:9rem;
				padding-bottom:6.5rem;
			}
			&:before {
				position: absolute;
				content:'';
				left:0;
				top:0;
				width:100%;
				height:100%;
				background:rgba(#000,0.3);
			}
			h1 {
				color:$light;
				position: relative;
				z-index: 9;
				span {
					color:$light;
				}
				small {
					color:$light;
				}
			}
		}
	}
}

body.tanzkurse-kinder {
	.sub-section {
		&:first-child {
			padding-top:4.5rem;
			background:url(../images/layout/kinder.jpg);
			background-size: cover;
			background-position:center center;
			padding-bottom: 1.5rem;
			position: relative;
			@include breakpoint(medium) {
			padding-top:9rem;
			padding-bottom:6.5rem;
			}
			@include breakpoint(huge) {
				padding-top:13rem;
				padding-bottom:10.5rem;
			}
			&:before {
				position: absolute;
				content:'';
				left:0;
				top:0;
				width:100%;
				height:100%;
				background:rgba(#000,0.3);
			}
			h1 {
				color:$light;
				position: relative;
				z-index: 9;
				span {
					color:$light;
				}
				small {
					color:$light;
				}
			}
		}
	}
}

body.tanzkurse-jugendliche {
	.sub-section {
		&:first-child {
			padding-top:4.5rem;
			background:url(../images/layout/jugendliche.jpg);
			background-size: cover;
			background-position:center center;
			padding-bottom: 1.5rem;
			position: relative;
			@include breakpoint(medium) {
			padding-top:9rem;
			padding-bottom:6.5rem;
			}
			@include breakpoint(huge) {
				padding-top:13rem;
				padding-bottom:10.5rem;
			}
			&:before {
				position: absolute;
				content:'';
				left:0;
				top:0;
				width:100%;
				height:100%;
				background:rgba(#000,0.3);
			}
			h1 {
				color:$light;
				position: relative;
				z-index: 9;
				span {
					color:$light;
				}
				small {
					color:$light;
				}
			}
		}
	}
}

body.tanzkurse-erwachsene {
	.sub-section {
		&:first-child {
			padding-top:4.5rem;
			background:url(../images/layout/erwachsene.jpg);
			background-size: cover;
			background-position:center center;
			padding-bottom: 1.5rem;
			position: relative;
			@include breakpoint(medium) {
			padding-top:9rem;
			padding-bottom:6.5rem;
			}
			@include breakpoint(huge) {
				padding-top:13rem;
				padding-bottom:10.5rem;
			}
			&:before {
				position: absolute;
				content:'';
				left:0;
				top:0;
				width:100%;
				height:100%;
				background:rgba(#000,0.3);
			}
			h1 {
				color:$light;
				position: relative;
				z-index: 9;
				span {
					color:$light;
				}
				small {
					color:$light;
				}
			}
		}
	}
}

body.tanzkurse-senioren {
	.sub-section {
		&:first-child {
			padding-top:4.5rem;
			background:url(../images/layout/senioren.jpg);
			background-size: cover;
			background-position:center center;
			padding-bottom: 1.5rem;
			position: relative;
			@include breakpoint(medium) {
			padding-top:9rem;
			padding-bottom:6.5rem;
			}
			@include breakpoint(huge) {
				padding-top:13rem;
				padding-bottom:10.5rem;
			}
			&:before {
				position: absolute;
				content:'';
				left:0;
				top:0;
				width:100%;
				height:100%;
				background:rgba(#000,0.3);
			}
			h1 {
				color:$light;
				position: relative;
				z-index: 9;
				span {
					color:$light;
				}
				small {
					color:$light;
				}
			}
		}
	}
}

body.tanzkurse-spezielle-tanzkurse {
	.sub-section {
		&:first-child {
			padding-top:4.5rem;
			background:url(../images/layout/spezial.jpg);
			background-size: cover;
			background-position:top center;
			padding-bottom: 1.5rem;
			position: relative;
			@include breakpoint(medium) {
			padding-top:9rem;
			padding-bottom:6.5rem;
			}
			@include breakpoint(huge) {
				padding-top:13rem;
				padding-bottom:10.5rem;
			}
			&:before {
				position: absolute;
				content:'';
				left:0;
				top:0;
				width:100%;
				height:100%;
				background:rgba(#000,0.3);
			}
			h1 {
				color:$light;
				position: relative;
				z-index: 9;
				span {
					color:$light;
				}
				small {
					color:$light;
				}
			}
		}
	}
}
body.tanzkurse-hochzeitspaare {
	.sub-section {
		&:first-child {
			padding-top:4.5rem;
			background:url(../images/layout/hochzeitspaare.jpg);
			background-size: cover;
			background-position:top center;
			padding-bottom: 1.5rem;
			position: relative;
			@include breakpoint(medium) {
			padding-top:9rem;
			padding-bottom:6.5rem;
			}
			@include breakpoint(huge) {
				padding-top:13rem;
				padding-bottom:10.5rem;
			}
			&:before {
				position: absolute;
				content:'';
				left:0;
				top:0;
				width:100%;
				height:100%;
				background:rgba(#000,0.3);
			}
			h1 {
				color:$light;
				position: relative;
				z-index: 9;
				span {
					color:$light;
				}
				small {
					color:$light;
				}
			}
		}
	}
}

body.veranstaltungen {
	.sub-section {
		&:first-child {
			padding-top:4.5rem;
			background:url(../images/layout/veranstaltungen.jpg);
			background-size: cover;
			background-position:top center;
			padding-bottom: 1.5rem;
			position: relative;
			@include breakpoint(medium) {
			padding-top:9rem;
			padding-bottom:6.5rem;
			}
			@include breakpoint(huge) {
				padding-top:13rem;
				padding-bottom:10.5rem;
			}
			&:before {
				position: absolute;
				content:'';
				left:0;
				top:0;
				width:100%;
				height:100%;
				background:rgba(#000,0.3);
			}
			h1 {
				color:$light;
				position: relative;
				z-index: 9;
				span {
					color:$light;
				}
				small {
					color:$light;
				}
			}
		}
	}
}

body.tanzpartnerboerse {
	.sub-section {
		&:first-child {
			padding-top:4.5rem;
			background:url(../images/layout/tanzpartner.jpg);
			background-size: cover;
			background-position:bottom center;
			padding-bottom: 1.5rem;
			position: relative;
			@include breakpoint(medium) {
			padding-top:9rem;
			padding-bottom:6.5rem;
			}
			@include breakpoint(huge) {
				padding-top:13rem;
				padding-bottom:10.5rem;
			}
			&:before {
				position: absolute;
				content:'';
				left:0;
				top:0;
				width:100%;
				height:100%;
				background:rgba(#000,0.3);
			}
			h1 {
				color:$light;
				position: relative;
				z-index: 9;
				span {
					color:$light;
				}
				small {
					color:$light;
				}
			}
		}
	}
}

body.impressionen {
	.sub-section {
		&:first-child {
			padding-top:4.5rem;
			background:url(../images/layout/impressionen.jpg);
			background-size: cover;
			background-position:center center;
			padding-bottom: 1.5rem;
			position: relative;
			@include breakpoint(medium) {
			padding-top:9rem;
			padding-bottom:6.5rem;
			}
			@include breakpoint(huge) {
				padding-top:13rem;
				padding-bottom:10.5rem;
			}
			&:before {
				position: absolute;
				content:'';
				left:0;
				top:0;
				width:100%;
				height:100%;
				background:rgba(#000,0.3);
			}
			h1 {
				color:$light;
				position: relative;
				z-index: 9;
				span {
					color:$light;
				}
				small {
					color:$light;
				}
			}
		}
	}
}

body.news {
	.sub-section {
		&:first-child {
			padding-top:4.5rem;
			background:url(../images/layout/news.jpg);
			background-size: cover;
			background-position:center center;
			padding-bottom: 1.5rem;
			position: relative;
			@include breakpoint(medium) {
			padding-top:9rem;
			padding-bottom:6.5rem;
			}
			@include breakpoint(huge) {
				padding-top:13rem;
				padding-bottom:10.5rem;
			}
			&:before {
				position: absolute;
				content:'';
				left:0;
				top:0;
				width:100%;
				height:100%;
				background:rgba(#000,0.3);
			}
			h1 {
				color:$light;
				position: relative;
				z-index: 9;
				span {
					color:$light;
				}
				small {
					color:$light;
				}
			}
		}
	}
}


body.kontakt , body.anfahrt{
	.sub-section {
		&:first-child {
			padding-top:4.5rem;
			background:url(../images/layout/kontakt-anfahrt.jpg);
			background-size: cover;
			background-position:center center;
			padding-bottom: 1.5rem;
			position: relative;
			@include breakpoint(medium) {
			padding-top:9rem;
			padding-bottom:6.5rem;
			}
			@include breakpoint(huge) {
				padding-top:13rem;
				padding-bottom:10.5rem;
			}
			&:before {
				position: absolute;
				content:'';
				left:0;
				top:0;
				width:100%;
				height:100%;
				background:rgba(#000,0.3);
			}
			h1 {
				color:$light;
				position: relative;
				z-index: 9;
				span {
					color:$light;
				}
				small {
					color:$light;
				}
			}
		}
	}
}

body.jobs-und-ausbildung {
	.sub-section {
		&:first-child {
			padding-top:4.5rem;
			background:url(../images/layout/job-ausbildung.jpg);
			background-size: cover;
			background-position:center center;
			padding-bottom: 1.5rem;
			position: relative;
			@include breakpoint(medium) {
			padding-top:9rem;
			padding-bottom:6.5rem;
			}
			@include breakpoint(huge) {
				padding-top:13rem;
				padding-bottom:10.5rem;
			}
			&:before {
				position: absolute;
				content:'';
				left:0;
				top:0;
				width:100%;
				height:100%;
				background:rgba(#000,0.3);
			}
			h1 {
				color:$light;
				position: relative;
				z-index: 9;
				span {
					color:$light;
				}
				small {
					color:$light;
				}
			}
		}
	}
}

body.impressum,body.datenschutzerklaerung,body.kundenmeinungen   {
	.sub-section {
		&:first-child {
			padding-top:4.5rem;
			background:url(../images/layout/jobs.jpg);
			background-size: cover;
			background-position:center center;
			padding-bottom: 1.5rem;
			position: relative;
			@include breakpoint(medium) {
			padding-top:9rem;
			padding-bottom:6.5rem;
			}
			@include breakpoint(huge) {
				padding-top:13rem;
				padding-bottom:10.5rem;
			}
			&:before {
				position: absolute;
				content:'';
				left:0;
				top:0;
				width:100%;
				height:100%;
				background:rgba(#000,0.3);
			}
			h1 {
				color:$light;
				position: relative;
				z-index: 9;
				span {
					color:$light;
				}
				small {
					color:$light;
				}
			}
		}
	}
}

#widget-jsXq8528{
	position: fixed;
    right: 0;
    bottom: 0;
	z-index: 999;
}