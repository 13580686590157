// Use this for default Icomoon

@include font-face-V2(
 $font-name: "icomoon",
 $file-name: "icomoon",
 $types: (woff, ttf, svg, eot),
 $style: normal,
);

// Example font-face for Roboto
@include font-face-V2(
    $font-name: "Barlow", 
    $file-name: "barlow-v11-latin", 
    $weights: ("400", "500", "700"), 
    $types: (woff, woff2)
);

@include font-face-V2(
    $font-name: "Tahu", 
    $file-name: "tahu", 
    $weights: ("400"), 
    $types: (woff, woff2)
);