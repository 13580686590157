// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$nav-bg: $medium; // Navigation Background
$nav-color: $dark; // Navigation Link Color

@mixin nav-button {
	// Default Link Styles
	text-decoration: none; 
	color: $nav-color;
	font-size:rem(20px);
	line-height: rem($base-line-height);
	@extend %animated-transform;

	@include breakpoint($break, max) {
		color:$dark;
	}

	@include breakpoint(large) {
		font-size:rem(15px);
	}
	@include breakpoint(giant) {
		font-size:rem(17px);
	}
	@include breakpoint(huge) {
		font-size:rem(20px);
	}
}
%button-hover {
	// Link Hover
	color: $primary;
}
%button-active {
	// Link Active
	color: $primary;
}
%prev-icon {
	// Icon to previus Submenu
	font-family: $icon-font;
	content: map-get($icon-map, angle-right);
	transform: rotateY(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%next-icon {
	// Icon to next Submenu
	font-family: $icon-font;
	content: map-get($icon-map, angle-right);

	@include breakpoint($break) {
		content: map-get($icon-map, angle-down);
		font-size:rem(18px);
		position: absolute;
		bottom:rem(-20px);
		left:50%;
		transform:translateX(-50%);
	}

	@include breakpoint(giant) {
		bottom:rem(-30px);
	}
}
$close-icon: map-get($icon-map, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$bar-height: 50px; // height (in px)
$bar-pos: top; // position (top, bottom)
$bar-hide: true; // if bar hides on scroll (true, false)

$bar-bg: $primary; // background-color
$bar-color: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include page-navi {
	background-color: $nav-bg;
	z-index: 1001;

	@include breakpoint($break) {
		background-color:transparent;
	}

	nav {
		@include ul {
			&.navi {
				li {
					@include nav-buttons {
						@include nav-button;
					}
					@include has-sub {
						@include sub {
							background: $nav-bg;

							@include breakpoint($break) {
								background: $dark;
							}

							&:before {
								padding: 1em rem($base-gap);
								background-color: darken($primary, 6%);
								color:$light;
							}
						}
					}
					@include nav-back {
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktop-version-functionality;

	#navigation {
		@include breakpoint($break) {
			pointer-events: none;

			body.cms-template-edit & {
				pointer-events: all;
			}
		}
		nav {
			ul {
				&.navi {
					li {
						@include breakpoint($break) {
							pointer-events: all;
						}
						&.index,
						&.jobs-und-ausbildung,
						&.impressum,
						&.datenschutzerklaerung,
						&.anfahrt,
						&.kontakt {
							display:none;
						}
						&.has-sub {
							.sub {
							}
						}
						&.nav-back {
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $bar-bg;
	color: $bar-color;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: rem($base-gap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.navi-add {
	padding-left:rem($base-gap);
	padding-right:rem($base-gap);
	@include breakpoint(medium) {
		display: flex;
		justify-content: center;
	}

	li {
		text-align:center;
		border-bottom:1px solid $border;

		&:last-of-type {
			margin-right:0;
			border-bottom:0 none;
		}

		@include breakpoint(medium) {
			text-align:left;
			margin-right: rem(30px);
			border-bottom:0 none;
		}

		@include breakpoint(huge) {
			margin-right: rem(75px);
		}

		a {
			color:$dark;
			font-size:rem(20px);
			display:block;
			padding:rem(12px) rem($base-gap);

			@include breakpoint(medium) {
				padding:0;
				font-size:rem(16px);
			}

			@include breakpoint(large) {
				font-size:rem(20px);
			}

 			&:hover,
			&:focus,
			&:active,
			&.active {
				color:$primary;
			}
		}
	}
}